import React, { useEffect, } from "react";
import {useSelector} from "react-redux";
import { message as msg } from 'antd';


export default () => {

    const error = useSelector(({common}) => common.error);
    const message = useSelector(({common}) => common.message);

    useEffect(() => {
        if(error){
          msg.open({
            type: 'error',
            content: error,
            duration: 5
          })
        }
    
        if(message){
          msg.open({
            type: 'success',
            content: message,
            duration: 5
          })
        }
    
      },[error, message]);

    return <></>

}