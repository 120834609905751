import React, {useState} from "react";
import {Button, Dropdown, Layout, Menu, message, Popover, Select, Row, Col} from 'antd';
import Icon from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import HorizontalNav from "../HorizontalNav";
import {Link} from "react-router-dom";
import {switchLanguage, toggleCollapsedSideNav} from "appRedux/actions/Setting";
import IntlMessages from "util/IntlMessages";
import Bg from "assets/images/wall.png";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, DEFAULT_PREFIX_PATH } from 'config/AppConfig'

const {Header} = Layout;
const Option = Select.Option;
const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">Products</Menu.Item>
    <Menu.Item key="2">Apps</Menu.Item>
    <Menu.Item key="3">Blogs</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info('Click on menu item.');
}

function handleChange(value) {
  console.log(`selected ${value}`);
}

const HorizontalDefault = () => {

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const locale = useSelector(({settings}) => settings.locale);
  const { navCollapsed} = useSelector(({common}) => common);
  const token = useSelector(({auth}) => auth.token);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value)
  };

  return (
    <div className="gx-header-horizontal" style={{background: 'transparent'}}>

      <Header
      style={{paddingBottom: '5px', paddingTop: '3px', height: 'auto'}}
        className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <Row justify="center">
              {/* <Col xl={11} lg={4} md={24} xs={24}>
                <Row justify="center">
                  <Col xl={12} lg={24} md={24} xs={24} style={{border: '1px solid red'}}>
                    <Link to="/home" className="gx-d-block gx-d-lg-none gx-pointer gx-w-logo">
                    <img style={{padding: "0px", margin: '0px'}} alt="" src={require("assets/images/pentagon.png")}/></Link>
                    <Link to="/home" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
                    <img style={{padding: "0px", margin: '0px'}} alt="" src={require("assets/images/pentagon.png")}/></Link>
                  </Col>
                </Row>
              </Col> */}
              <Col style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}} xl={5} lg={24} md={8} xs={24}>
                {
                  token ? (
                    <Link style={{verticalAlign: "middle",padding: '0px', margin: '0px', height: 'auto'}} to={`${APP_PREFIX_PATH}/dashboard`}>
                        <Button style={{backgroundColor: '#d91d3b!important', verticalAlign: "middle",paddingBottom: '0px', marginBottom: '0px', height: 'auto'}} type="primary" block>
                            Dashboard
                        </Button>
                    </Link>
                  ) : (
                    <Row justify="center">
                      <Link style={{verticalAlign: "middle",padding: '0px', margin: '0px', height: 'auto',}} to={`${APP_PREFIX_PATH}/dashboard`}>
                          <Button style={{backgroundColor: '#d91d3b!important', verticalAlign: "middle",paddingBottom: '0px', marginBottom: '0px', height: 'auto'}} type="primary" block>
                              Start Earning
                          </Button>
                      </Link>
                      {/* <div style={{width: "20px"}}>

                      </div>
                      <Link style={{verticalAlign: "middle",padding: '0px', margin: '0px', height: 'auto'}} to={`${AUTH_PREFIX_PATH}/signup`}>
                          <Button style={{verticalAlign: "middle",paddingBottom: '0px', marginBottom: '0px', height: 'auto'}}  block>
                              Sign Up
                          </Button>
                      </Link> */}
                    </Row>
                  )
                }
              </Col>
            </Row>
            
          </div>
        </div>
      </Header>
    </div>
  );
};

export default HorizontalDefault;
