import React from 'react'
import App from "../../Views/Defaulf";
import {useRouteMatch} from "react-router-dom";
import {Layout, Affix} from 'antd';
import Bg from "assets/images/wall.png";
import InfoView from 'components/InfoView/mainAppInfo'
import HeaderCustom from "components/LayoutComponents/Topbar/BootStrapHeader";
import AuthPopUp from 'components/shared/authPopup';
import CustomFooter from 'components/Footer';
import {footerText} from "util/config";
import {isMobile, isTablet, isIPad13} from "react-device-detect";
const {Content, Header, Footer} = Layout;

const Default = (props) => {
    const match = useRouteMatch();
    const heightDivider = isMobile ?  window.innerWidth === 768 ? '150px' :'80px' : '150px';
    let H = props.location.pathname !== '/home'? "auto": "100%";
    let headerBg = props.location.pathname !== '/home'? "#FFF": "#3D2071";

    return (
        <>
        {/* style={{backgroundImage: `url(${Bg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', overflowX: "hidden"}} */}
        <div style={{overflowX: "hidden", overflowY: props.location.pathname !== '/home' ? "hidden" : 'scroll', height: H}}>
            <Layout style={{height: H}} className="gx-applayout">
                <Layout style={{minHeight: `${window.innerHeight}px`}}>
                    <InfoView/>
                    <AuthPopUp/>
                    <HeaderCustom user={props.user} token={props.token} bg={headerBg} heightDivider={heightDivider}/>
                    
                    <Content style={{height: H}} className="gx-layout-content gx-container-wrap">                      
                        <App {...props} match={match} />
                    </Content>                   
                </Layout>
                {props.location.pathname !== '/home' && (
                    // <Footer>
                    //     <div className="gx-layout-footer-content">
                    //         {footerText}
                    //     </div>
                    // </Footer>
                    <CustomFooter bg={props.location.pathname !== '/home' ? null : headerBg}/>
                )}
                
            </Layout>
        </div>
        </>
    )
}

export default Default;