import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Wallet from "./Wallet";
import Common from "./Common";
import Transaction from "./Transaction";
import Feed from "./Feed";
import Comment from "./Comment";
import {connectRouter} from 'connected-react-router'

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  wallet: Wallet,
  transaction: Transaction,
  common: Common,
  feed: Feed,
  comment: Comment,

});
