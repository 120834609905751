import React, {useEffect} from "react";
import InfoView from 'components/InfoView/authMessage'
import App from "../../Views/Auth/";

import {useRouteMatch} from "react-router-dom";

const MainApp = () => {
  const match = useRouteMatch();

  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
  },[]);


  return (
    <>
      <InfoView/>
      <App match={match}/>
      
    </>
          
  )
};
export default MainApp;

