import {
    TRANSACTION_LIST,
    TRANSACTION_SEARCH
} from "../../constants/ActionTypes";

const INIT_STATE = {
    transactionSearch: null,
    transactionList: null
};

export default (state = INIT_STATE, action ) => {
  switch (action.type) {


    case TRANSACTION_LIST: {
      return {
        ...state,
        transactionList: action.payload,
      };
    }

    case TRANSACTION_SEARCH: {
      return {
        ...state,
        transactionSearch: action.payload,
      };
    }

    default:
      return state;
  }
}
