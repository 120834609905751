import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    WALLET_INFO,
    SHOW_MESSAGE,
    SET_WALLET
  } from "constants/ActionTypes";
  import axios from 'util/Api'

  export const getWalletInfo = () => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.get(`wallet/info`).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: WALLET_INFO, payload: data.data});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        //console.log(error.response);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const activateWallet = ({address}) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post(`wallet/activate`, {
        address
      }
      ).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload : data.message});
          dispatch({type: SET_WALLET, payload: true});
          // localStorage.setItem("user", JSON.stringify(data.data));
          localStorage.setItem("walletSet", true);
        } else {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const WithDrawFund = ({address, amount}) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post(`wallet/withdraw-fund`, {
        address,
        amount
      }
      ).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload : data.message});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };