import React from "react";
// import loader from "../../assets/images/loader.svg"
import loader from "../../assets/images/pre-bee-bucket.gif"
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";

const CircularProgress = ({className}) => {
const location = useLocation();
let bgColor = location.pathname !== '/home'? "#FFF": "#3D2071";
return (<div style={{backgroundColor: bgColor, width: '100%', position: 'absolute'}} className={`loader ${className}`}><div style={{backgroundColor: bgColor}} className={`loader ${className}`}>
  <img src={loader} alt="loader"/>
</div></div>)}
export default CircularProgress;
