import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    ALL_COMMENTS,
    COMMENT,
    EARNSTATUS,
    SHOW_MESSAGE,
    UPDATE_INFO
  } from "constants/ActionTypes";
  import axios from 'util/Api';

  export const addComment = ({ subject, subjectRef, content }) => {
    return (dispatch) => {
      //dispatch({type: FETCH_START});
      axios.post('comment/create', { subject, subjectRef, content }).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload : data.message});
          dispatch({type: COMMENT, payload: data.data.comment});
          dispatch({type: EARNSTATUS, payload: data.data.earned});
          dispatch({type: UPDATE_INFO, payload: 'comment'});
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {

        //console.log(error.response);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const getComments = (feed) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.get(`comment/all?feed=${feed}`).then(({data}) => {
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: ALL_COMMENTS, payload: data.data});
  
        } else {
          //console.log("payload: data.error", data.message);
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      }).catch(error => {

        //console.log(error.response);
        dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      });
    }
  };

  export const toggleCelebration = (state) => {
    return (dispatch) => {
      dispatch({type: EARNSTATUS, payload: state});
    }
  };