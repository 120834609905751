import axios from 'axios';

let baseURL;
process.env.NODE_ENV !== 'production' ?
 baseURL = 'http://localhost:4000/api/v1/' :
 baseURL = 'https://beeng.herokuapp.com/api/v1/';

//  stagging : https://beeng.herokuapp.com/api/v1/

export default axios.create({
  // withCredentials: true,
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  }
});