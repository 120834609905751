import {message} from "antd";
import jwt_decode from 'jwt-decode';
import { AUTH_PREFIX_PATH } from 'config/AppConfig'

export const expireJwt = (history, authed, logout) => {

    if(authed){

        let token =  localStorage.token;

        let jwt = jwt_decode(token, {headers: true});

        let current_time = Date.now().valueOf() / 1000;

        if(jwt.exp < current_time){

            logout();

            history.push(AUTH_PREFIX_PATH);
            message.open({
              type: 'error',
              content: 'Your Token Session Is Expired Please Sign In Again',
              duration: 5
            })

            return false;
        }

    }
}