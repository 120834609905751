import {
  FEED_DATA_STACK,
  LATEST_FEEDS,
  SEARCHED_FEEDS,
  CATEGORY_FEEDS,
  UPDATE_INFO,
  FEED_INFO,
  FEEDS,
  REACTED
} from "../../constants/ActionTypes";

const INIT_STATE = {
    latest: null,
    featured: null,
    trending: null,
    all: null,
    activity: null,
    info: null,
    category: null,
    search: null,
    reacted: false,
    commented: false,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case FEED_DATA_STACK: {
      const { 
        latest, 
        activity 
      } = action.payload;

      return {
        ...state,
        latest: latest,
        activity: activity
      };
    }

    case FEEDS: {
      return {
        ...state,
        all: action.payload
      };
    }

    case LATEST_FEEDS: {
      return {
        ...state,
        latest: action.payload
      };
    }

    case SEARCHED_FEEDS: {
      return {
        ...state,
        search: action.payload
      };
    }

    case CATEGORY_FEEDS: {
      return {
        ...state,
        category: action.payload
      };
    }

    case FEED_INFO: {
      return {
        ...state,
        info: action.payload
      };
    }

    case UPDATE_INFO: {
      return action.payload === 'reaction' 
      ?
        {
          ...state,
          info: {
            ...state.info,
            kpi: {
              ...state.info.kpi,
              reaction: state.reacted === true ? state.info.kpi.reaction - 1 : state.info.kpi.reaction + 1
            }
          }, //(3*(6+0) quadratic)
          reacted: !state.reacted,
        }
      :
        {
          ...state,
          info: {
            ...state.info,
            kpi: {
              ...state.info.kpi,
              comments: state.info.kpi.comments + 1
            }
          }, //(3*(6+0) quadratic)
          commented: true,
        }
    }

    case REACTED: {
      return {
        ...state,
        reacted: action.payload
      };
    }

    default:
      return state;
  }
}
