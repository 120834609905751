import React, { lazy, Suspense } from "react";

import {Redirect, Route, Switch} from "react-router-dom";

import CircularProgress from "components/CircularProgress";

const Auth = ({match, location}) => {
    return (

        <Suspense fallback={<CircularProgress />}>
            <Switch>
                <Route exact path={`${match.url}`}>
                    <Redirect to={`${match.url}/signin`} />
                </Route>
                <Route exact path={`${match.url}/signin`} component={lazy(() => import(`./SignIn`))}/>
                <Route exact path={`${match.url}/signup`} component={lazy(() => import(`./SignUp`))}/>
                <Route exact path={`${match.url}/re-verify`} component={lazy(() => import(`./ResendVerification`))}/>
                <Route exact path={`${match.url}/verify/:accessToken`} component={lazy(() => import(`./VerifyAccount`))}/>
                <Route exact path={`${match.url}/forgotpassword`} component={lazy(() => import(`./ForgotPassword`))}/>
                <Route exact path={`${match.url}/reset/:accessToken`} component={lazy(() => import(`./Reset`))}/>
                <Redirect
                    to={{
                        pathname: `${match.url}/signin`,
                        state: {from: location}
                    }}
                />
            </Switch>
        </Suspense>

    )
}

export default Auth;