import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  FORGOT_MAIL_SENT,
  PASSWORD_RESET,
  SHOW_MESSAGE,
  SET_WALLET,
  USER_VERIFIED,
  MAIL_RESENT,
  OPEN_AUTH
} from "../../constants/ActionTypes";
import axios from 'util/Api'

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, username, referer}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/signup', {
        email: email,
        password: password,
        username: username,
        referer: referer
      }
    ).then(({data}) => {
      console.log("data:", data);
      if (data.success) {
        if(localStorage.ref_code){
          localStorage.removeItem('ref_code');
        }
        // localStorage.setItem("token", data.data.token);
        // localStorage.setItem("user", JSON.stringify(data.data));
        // axios.defaults.headers.common.Authorization = "Bearer " + data.data.token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload : data.message});
        // dispatch({type: USER_DATA, payload: data.data.user});
        // dispatch({type: USER_TOKEN_SET, payload: data.data.token});

      } else {
        console.log("payload: data.error", data.data.message);
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};

export const continueWithGoogle = ({token, prefix}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`auth/googleAuth${prefix || ''}`, {
      token
      }
    ).then(({data}) => {
      //console.log("userSignIn: ", data);
      if (data.data.token) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("reftoken", data.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(data.data));
        localStorage.setItem("walletSet", data.data.user.oneTimeSetup);
        //axios.defaults.headers.common.Authorization = "Bearer " + data.data.token;
        axios.defaults.headers.Authorization = "Bearer " + data.data.token;
        axios.defaults.headers.reftoken = data.data.refreshToken
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.data});
        dispatch({type: USER_TOKEN_SET, payload: data.data.token});
        dispatch({type: SET_WALLET, payload: data.data.user.oneTimeSetup});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};

export const userSignIn = ({email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/signin', {
        email: email,
        password: password,
      }
    ).then(({data}) => {
      //console.log("userSignIn: ", data);
      if (data.data.token) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("reftoken", data.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(data.data));
        localStorage.setItem("walletSet", data.data.user.oneTimeSetup);
        //axios.defaults.headers.common.Authorization = "Bearer " + data.data.token;
        axios.defaults.headers.Authorization = "Bearer " + data.data.token;
        axios.defaults.headers.reftoken = data.data.refreshToken
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.data});
        dispatch({type: USER_TOKEN_SET, payload: data.data.token});
        dispatch({type: SET_WALLET, payload: data.data.user.oneTimeSetup});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};

//resend-verify-mail

export const resendVerificationMail = (email) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/resend-verify-mail', {
      email: email
    }
    ).then(({data}) => {
      console.log("resend verify mail: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload : data.message});
        dispatch({type: MAIL_RESENT, payload: true});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.get('users/me',
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};

export const forgotPassword = ({email}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/reset', {
      email: email
    }
    ).then(({data}) => {
      console.log("Forgot Password: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload : data.message});
        dispatch({type: FORGOT_MAIL_SENT, payload: true});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};

export const updateAccount = ({eligibleVoter, region, dateOfBirth}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`user/update-account`, {
      eligibleVoter: eligibleVoter ? true : false, 
      region, 
      dateOfBirth
    }
    ).then(({data}) => {
      if (data.success) {
        localStorage.setItem("walletSet", true);
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload : data.message});
        dispatch({type: SET_WALLET, payload: true});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};


export const resetPassword = ({password, accessToken}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`auth/reset/${accessToken}`, {
      password: password
    }
    ).then(({data}) => {
      console.log("Reset: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload : data.message});
        dispatch({type: PASSWORD_RESET, payload: true});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};

export const changePassword = ({current, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`auth/resetPassword`, {
      current: current,
      password: password
    }
    ).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload : data.message});
        dispatch({type: PASSWORD_RESET, payload: true});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.data.message});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    });
  }
};

export const userVerify = (accessToken) => {
  return (dispatch) => {
    //dispatch({type: FETCH_START});
    axios.get(`auth/verify/${accessToken}`).then(({data}) => {
      if (data.success) {
        //dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_VERIFIED, payload: true});
        dispatch({type: SHOW_MESSAGE, payload : data.data.message});

      } else {
        console.log("payload: data.error", data.message);
        dispatch({type: FETCH_ERROR, payload: data.data.message});
        dispatch({type: USER_VERIFIED, payload: undefined});
      }
    }).catch(error => {
      dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
      dispatch({type: USER_VERIFIED, payload: undefined});
    });
  }
};

export const refreshToken = () => {
  return (dispatch) => {
    axios.post(`auth/renew-refresh-token`).then(({data}) => {
      if (data.success) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("reftoken", data.data.refreshToken);
        axios.defaults.headers.Authorization = "Bearer " + data.data.token;
        axios.defaults.headers.reftoken = data.data.refreshToken
        //dispatch({type: USER_TOKEN_SET, payload: data.data.token});

      } else {
        console.log("payload: data.error", data.data.message);
        //dispatch({type: FETCH_ERROR, payload: data.message});
      }
    }).catch(error => {

      //const {message} = error.response.data.errors;
      //dispatch({type: FETCH_ERROR, payload: message || error.message});
    });
  }
};


export const userSignOut = () => {

  return (dispatch) => {
    //dispatch({type: FETCH_START});

    // axios.post('auth/signout').then(({data}) => {
    //   console.log("log out",data)
    //   if (data) {
    //     localStorage.removeItem("token");
    //     dispatch({type: FETCH_SUCCESS});
    //     dispatch({type: SIGNOUT_USER_SUCCESS});
    //   } else {
    //     dispatch({type: FETCH_ERROR, payload: data.error});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: FETCH_ERROR, payload: error.message});
    //   console.log("Error****:", error.message);
    // });
    dispatch({type: SIGNOUT_USER_SUCCESS});

    localStorage.removeItem("token");
    localStorage.clear();
    
  }

};

export const deleteUserAccount = () => {
  return (dispatch) => {

    dispatch({type: FETCH_START});
    
    axios.post('auth/delete-account').then(({data}) => {
    if (data.success) {
      localStorage.removeItem("token");
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: SIGNOUT_USER_SUCCESS});
      dispatch({type: SHOW_MESSAGE, payload : data.message});
      localStorage.clear();
    } else {
      dispatch({type: FETCH_ERROR, payload: data.data.message});
    }
  }).catch(function (error) {
    dispatch({type: FETCH_ERROR, payload: 'Check Your internet and refresh page'});
    console.log("Error****:", error.message);
  });
  }
}

export function authPopUp(status) {
  return {type: OPEN_AUTH, payload: status};
}
