import React, { lazy, Suspense, useEffect } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import { AUTH_PREFIX_PATH } from 'config/AppConfig';

const DefaultApp = ({history, match, location, token}) => {

    const RestrictedRoute = ({component: Component, location, reRoute, condition, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            condition
            ? <Component {...props} />
            : <Redirect
                to={{
                pathname: `${reRoute}`,
                state: {from: location}
                }}
            />}
    />;

    return (

        <Suspense fallback={<CircularProgress />}>
            <Switch>
                <Route 
                    exact 
                    path={`${match.url}/`} 
                    component={lazy(() => import(`./home`))} dedd
                />

                <Route 
                    exact 
                    path={`${match.url}/about`} 
                    component={lazy(() => import(`./about`))} 
                />

                <Route 
                    exact 
                    path={`${match.url}/faq`} 
                    component={lazy(() => import(`./faq`))} 
                />

                <Route 
                    exact 
                    path={`${match.url}/feed`} 
                    component={lazy(() => import(`./feed`))} 
                />

                <Route 
                    exact 
                    path={`${match.url}/:feed`} 
                    component={lazy(() => import(`./read`))} 
                />

                <Redirect
                    to={{
                        pathname: `${match.url}/`,
                        state: { from: location }
                    }}
                />
            </Switch>
        </Suspense>

    )
}

export default DefaultApp;