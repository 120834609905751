import React, { useContext, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  HomeOutlined,
  UserOutlined,
  BellOutlined,
  AlignRightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { Row, Col, Button, Avatar, Badge, Dropdown, Menu } from "antd";
// import isAuthenticated from "../../utils/isAuthenticated";

import Logo from "assets/images/beengLogo-removebg-preview.png";
import { message } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, DEFAULT_PREFIX_PATH } from 'config/AppConfig';
import { userSignOut } from "appRedux/actions/Auth";

const Header = ({bg, token, user, heightDivider}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuthenticated = token;

  const history = useHistory();

  const handleUserLogout = () => {
    dispatch(userSignOut())
    message.open({
      type: 'success',
      content: 'you have successfully signed out',
      duration: 5
    })
  };

  const menu = (
    <Menu style={{zIndex: "9000"}}>
      <Menu.Item key="0">
        <Link to={`${APP_PREFIX_PATH}`} >
          Wallet
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to={`${APP_PREFIX_PATH}/transaction`}>
          Transactions
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to={`${APP_PREFIX_PATH}/settings`}>
          Settings
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={() => handleUserLogout()} disabled={false}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
    <div style={{ zIndex: "1000" }} className="header-main __web-inspector-hide-shortcut__ dashboard">
      <div className="header-container container">
        <div className="header-wrap menu header-navbar">
          <div
            className="header-navbar"
            style={{
              display: "flex",
              zIndex: "3000"
            }}
          >
            <nav
              className="navbar navbar-expand-lg navbar-dark navbar fixed-top  "
              id="mainNav"
              style={{ backgroundColor: "#3D2071", zIndex: "8000" }}
            >
              <div className="container">
                <Col xl={4} lg={4} md={20} xs={20}>
                <div className="header-logo logo">
                  <Link to={DEFAULT_PREFIX_PATH} className=" navbar-brand js-scroll-trigger">
                    <img src={Logo} />
                  </Link>
                </div>
                </Col>
                <button
                  style={{borderColor:  bg === "#FFF" ? "#3d207199" : "#FFF"}}
                  className="navbar-toggler navbar-toggler-right"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span style={{backgroundColor:  bg === "#FFF" ? "#3d207199" : "transparent"}} className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarResponsive"
                  style={{ justifyContent: "space-between" }}
                >
                  <Col xl={10} lg={10} md={24} xs={24}>
                      {isMobile && window.innerWidth < 1024 && <Row justify="start" className="navbar-nav menu" style={{border: `1px solid #3D2071`}}>
                        <Col style={{marginBottom: '20px'}} xl={4} lg={4} md={24} xs={24} className="menu-item ">
                          <Link className="menu-link  " to={`${DEFAULT_PREFIX_PATH}/feed`}>
                            Feed
                          </Link>
                        </Col>
                        <Col style={{marginBottom: '20px'}} xl={4} lg={4} md={24} xs={24} className="menu-item">
                          <Link className="menu-link" to={`${DEFAULT_PREFIX_PATH}/about`}>
                            About
                          </Link>
                        </Col>
                        <Col style={{marginBottom: '20px'}} xl={4} lg={4} md={24} xs={24} className="menu-item ">
                          <Link className="menu-link  " to={`${DEFAULT_PREFIX_PATH}/faq`}>
                            FAQ
                          </Link>
                        </Col>
                      </Row>}
                      {(!isMobile || window.innerWidth >= 1024) && <Row justify="start" className="navbar-nav menu" style={{border: `1px solid #3D2071`}}>
                        <Col xl={4} lg={4} md={24} xs={24} className="menu-item ">
                          <Link className="menu-link  " to={`${DEFAULT_PREFIX_PATH}/feed`}>
                            Feed
                          </Link>
                        </Col>
                        <Col xl={4} lg={4} md={24} xs={24} className="menu-item">
                          <Link className="menu-link" to={`${DEFAULT_PREFIX_PATH}/about`}>
                            About
                          </Link>
                        </Col>
                        <Col xl={4} lg={4} md={24} xs={24} className="menu-item ">
                          <Link className="menu-link  " to={`${DEFAULT_PREFIX_PATH}/faq`}>
                            FAQ
                          </Link>
                        </Col>
                      </Row>}
                  </Col>
                  <Col xl={7} lg={9} md={24} xs={24} >
                      {!isAuthenticated && <Row justify="start" className="navbar-nav menu" style={{border: `1px solid #3D2071`}}>
                        <Col xl={6} lg={6} md={12} xs={12} className="menu-item">
                          <Link className="menu-link" to={`${AUTH_PREFIX_PATH}/signin`}>
                            <Button style={{borderColor: "#038fde"}} type="link">Sign in</Button>
                          </Link>
                        </Col>
                        <Col xl={6} lg={6} md={12} xs={12} className="menu-item ">
                          <Link className="menu-link" to={`${AUTH_PREFIX_PATH}/signup`}>
                            <Button type="primary">Get Started</Button>
                          </Link>
                        </Col>
                      </Row>}
                      {
                        isAuthenticated && (
                          <>
                            <div key={1} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
                              <div className="gx-media-body">
                                <Link to={`${APP_PREFIX_PATH}`}><span style={{fontSize: "20px"}} className="gx-mb-0 gx-text-grey gx-fs-md">{user.user.username || user.user.email}💪</span></Link>
                              </div>
                              <div style={{zIndex: "9000"}} className="gx-mr-3">
                                <Dropdown style={{zIndex: "9000"}} overlay={menu}>
                                  <Badge status="success" dot>
                                    <Avatar src={user.user.picture} />
                                  </Badge>
                                </Dropdown>
                              </div>
                            </div>
                          </>
                        )
                      }
                  </Col>
                </div>
              </div>
            </nav>
            <div className="header-navbar-overlay"></div>
          </div>
        </div>
      </div>
    </div>
    <div style={{width: "100%", height: `${heightDivider}` || "150px", backgroundColor: bg === "#FFF" ? "transparent" : bg}}></div>
    </>
  );
};
export default Header;
