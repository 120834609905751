import {
    //FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    TRANSACTION_LIST,
    TRANSACTION_SEARCH,
    //SHOW_MESSAGE,
  } from "constants/ActionTypes";
  import axios from 'util/Api'

export const listTransaction = () => {
    return (dispatch) => {
      //dispatch({type: FETCH_START});
      axios.get('transaction/list').then(({data}) => {
        //console.log("data:", data);
        if (data.success) {
          //dispatch({type: FETCH_SUCCESS});
          dispatch({type: TRANSACTION_LIST, payload: data.data});
  
        } else {
          //console.log("payload: data.error", data.message);
          //dispatch({type: FETCH_ERROR, payload: data.message});
          dispatch({type: TRANSACTION_LIST, payload: []});
        }
      }).catch(error => {

        //console.log(error.response);
        dispatch({type: TRANSACTION_LIST, payload: []});
        //dispatch({type: FETCH_ERROR, payload: error.message});
        // dispatch({type: FETCH_ERROR, payload: error.response.data.errors.message});
      });
    }
  };