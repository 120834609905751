import React from 'react';
import './footer.css';
import {Link} from 'react-router-dom';
// import isAuthenticated from '../../utils/isAuthenticated'
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, DEFAULT_PREFIX_PATH } from 'config/AppConfig';

import Logo from "assets/images/beengLogo-removebg-preview.png";

const Footer = ({ cardHeader, cardText, buttonText, bg }) => {
    const date = new Date();
    let currentDate = date.getFullYear();
 return(
     <>
     <br/>
     <br/>
    <footer className="nk-footer  has-ovm">
    <section className="section section-footer section-l tc-light bg-theme padme-100" style={{background: bg ? bg : "#FFF"}} >
    
        <div className="container">
       
            <div className="nk-block block-footer">
                <div className="row">
                    <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                        <div className="wgs wgs-menu">
                            {/* <h6 className="wgs-title">Products</h6>
                            <div className="wgs-body">
                                <ul className="wgs-links" style={{listStyleType: 'none', margin: 'none', padding: 'none'}}>
                                    <li><Link to={`${DEFAULT_PREFIX_PATH}/faq`}>FAQ</Link></li>
                                    <li><Link to={`${DEFAULT_PREFIX_PATH}/about`}>About Us</Link></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                        <div className="wgs wgs-menu">
                            <h6 className="wgs-title">Help</h6>
                            <div className="wgs-body">
                                <ul className="wgs-links" style={{listStyleType: 'none'}}>
                                   <li><a  href="https://docs.google.com/document/d/1N1MYL_gLo2zJ_lxdRvJXKkg_11AzI4Ou6-jM_nh5mU4/edit?usp=sharing"
                                        target="_blank">Whitepaper</a></li>
                                        <li><Link to={`${DEFAULT_PREFIX_PATH}/faq`}>FAQ</Link></li>
                                    <li><Link to={`${DEFAULT_PREFIX_PATH}/about`}>About Us</Link></li>
                                    {/* <li><a target="blank" href="#">Terms &amp; Conditions</a></li>
                                    <li><a target="blank" href="#">Privacy Policy</a></li> */}

                                    {/* <li><Link to="/terms">Terms &amp; Conditions</Link></li>
                                    <li><Link to="/privacy">Privacy Policy</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                        <div className="wgs wgs-menu">
                            <h6 className="wgs-title">Community</h6>
                            <div className="wgs-body">
                                <ul className="wgs-links" style={{listStyleType: 'none'}}>
                                    <li><a href="https://www.facebook.com/boldexpreneur/" target="_blank">Facebook</a></li>
                                    <li><a href="https://twitter.com/boldexpreneur" target="_blank">Twitter</a></li>
                                    <li><a href="https://www.instagram.com/boldexpreneur" target="_blank">Instagram</a></li>
                                    {/* <li><a href="" target="_blank">Linkedin</a></li> */}
                                    <li><a href="https://t.me/GI_officialbee" target="_blank">Telegram</a></li>
                                    <li><a href="http://boldexpreneur.medium.com/" target="_blank">Medium</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-0 order-lg-first" id="footer">
                        <div className="wgs wgs-text">
                            <div className="wgs-body col-sm-4">
                                <Link to="/" className="wgs-logo">
                                <img src={Logo} />
                                </Link>
                                <p>Copyright © {currentDate} Boldexpreneur. <br/> info@boldexpreneur.com</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
    <div className="nk-ovm shape-b"></div>
</footer>
</>
 )
}

export default Footer;