import React, { useState, useEffect } from 'react';
import { Modal, Result, Button } from 'antd';
import {
    useDispatch,
    useSelector
  } from "react-redux";
  import {Link} from "react-router-dom";
  import {authPopUp} from "appRedux/actions";
  import { AUTH_PREFIX_PATH } from 'config/AppConfig'

const AuthPopUp = () => {
  const dispatch = useDispatch();
  const open = useSelector(({auth}) => auth.open);

  const handleCancel = () => {
    dispatch(authPopUp(false));
  };

  return (
    <>
      <Modal footer={null}  visible={open} onCancel={handleCancel} bodyStyle={{marginTop: "50px"}}>
        <Result
                status="error"
                title="This action Requires Authentication"
                subTitle="Please signin or register to access all priviledges."
                extra={[
                  <Link to={`${AUTH_PREFIX_PATH}/signin`}>
                    <Button type="primary" key="console">
                      Sign in
                    </Button>
                  </Link>,
                  <Link to={`${AUTH_PREFIX_PATH}/signup`}>
                    <Button key="buy">Register</Button>
                  </Link>,
                ]}
            />
      </Modal>
    </>
  );
};

export default AuthPopUp