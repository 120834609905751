import {
  ALL_COMMENTS,
  COMMENT,
  EARNSTATUS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
    commented: null,
    allComments: null,
    earned: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case ALL_COMMENTS: {

      return {
        ...state,
        allComments: action.payload,
      };
    }

    case COMMENT: {
      return {
        ...state,
        ...state.allComments.unshift(action.payload),
        commented: action.payload,
        // allComments: state.allComments.push(action.payload),
      };
    }

    case EARNSTATUS: {
      return {
        ...state,
        earned: action.payload,
      };
    }

    default:
      return state;
  }
}
