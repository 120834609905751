import {
  WALLET_INFO,
  SET_WALLET
} from "../../constants/ActionTypes";

const INIT_STATE = {
    walletInfo: null,
    walletSetup: localStorage.getItem('walletSet') || false

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case WALLET_INFO: {
      return {
        ...state,
        walletInfo: action.payload,
      };
    }

    case SET_WALLET: {
        return {
          ...state,
          walletSetup: action.payload,
        };
      }

    default:
      return state;
  }
}
