import React, { useEffect, } from "react";
import {useSelector} from "react-redux";
import {notification } from "antd";


export default () => {

    const error = useSelector(({common}) => common.error);
    const message = useSelector(({common}) => common.message);

    const openNotification = (type, placement, news, desc) => {
        notification[type]({
        message: news,
        description:
            desc,
        placement,
        });
    };

    useEffect(() => {
        if(error){
        openNotification('error','bottomRight', 'An Error Occured', error)
        }

        if(message){
        openNotification('success','bottomRight', 'Successful', message)
        }

    },[error, message]);

    return <></>

}