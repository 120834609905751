import React, { lazy, Suspense, useEffect } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import CircularProgress from "components/CircularProgress";

const MainApp = ({history, match, location, accounSetUpComplete }) => {
    const RestrictedRoute = ({component: Component, location, walletSet, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            walletSet
            ? <Component {...props} />
            : <Redirect
                to={{
                pathname: `${match.url}/dashboard`,
                state: {from: location}
                }}
            />}
    />;

    return (
        <Suspense fallback={<CircularProgress />}>
            <Switch>
                {
                    accounSetUpComplete &&
                   
                        (<Route 
                            exact 
                            path={`${match.url}/dashboard`} 
                            component={lazy(() => import(`./Dashboard`))} 
                        />)
                }{

                        accounSetUpComplete &&

                        (<Route 
                            path={`${match.url}/settings`} 
                            component={lazy(() => import('./settings'))} 
                        />)
                    
                }{

                    accounSetUpComplete &&

                    (<Route 
                        path={`${match.url}/transaction`} 
                        component={lazy(() => import('./Transaction'))} 
                    />)
                }
                {
                    <Route 
                    path={`${match.url}/add-feed`} 
                    component={lazy(() => import('./Feed'))} />
                }

                {
                    !accounSetUpComplete && <Route 
                    path={`${match.url}/setup-account`} 
                    component={lazy(() => import('./OneTimeSetup'))} 
                />
                //     <RestrictedRoute 
                //     path={`${match.url}/setup-wallet`} 
                //     location={location}
                //     walletSet={localStorage.walletSet === 'false' || !setupWallet}
                //     component={lazy(() => import('./OneTimeSetup'))} 
                // />
                }

                <Redirect
                    to={{
                        pathname: accounSetUpComplete ? `${match.url}/dashboard` : `${match.url}/setup-account`,
                        state: { from: location }
                    }}
                />
            </Switch>
        </Suspense>

    )
}

export default MainApp;